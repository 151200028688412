import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ApiItem from './components/ApiItem'

function App () {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = () => {
      axios
        .get('https://api.publicapis.org/entries')
        .then(response => {
          setData(response.data.entries)
        })
        .catch(err => console.log(err))
    }
    fetchData()
  }, [])

  return (
    <div className='w-full h-full bg-slate-900 p-4'>
      <div className='flex items-center'>
        <div className='flex flex-wrap overflow-hidden text-white gap-2'>
          {data &&
            data.map(item => <ApiItem key={item.description} data={item} />)}
        </div>
      </div>
    </div>
  )
}

export default App
