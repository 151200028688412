import React from 'react'

export default function ApiItem ({ data }) {
  return (
    <div className='w-1/4 overflow-hidden'>
      <div className='h-64 bg-slate-200 text-black p-8 rounded-lg shadow-lg flex flex-col justify-center items-center gap-2'>
        <div>{data.API}</div>
        <div>{data.Description}</div>
        <div>
          <a href={data.Link} target='_blank' rel='noreferrer'>
            <button
              className='bg-slate-400 px-4 py-2 rounded-lg'
              href={data.link}
              target='_blank'
            >
              Link do Api
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}
